<template>
  <div class="container">
    <main>
      <div class="grid grid-cols-12 mb-8 gap-4">
        <router-link
          to="/"
          class="bg-primary-500 text-white px-2 text-center rounded-md col-span-4 lg:col-span-1 flex justify-center items-center"
        >
          <i class="mdi mdi-arrow-left"></i> Indietro
        </router-link>
        <span class="col-span-8 lg:col-span-11">
          <router-link to="/" class="hover:underline">Home</router-link>
          <i class="mdi mdi-menu-right"></i>
          <router-link
            :to="`/${$route.params.lang}/store/${product.company_name_slug}`"
            class="hover:underline"
            >{{ product.company_name }}</router-link
          >
          <i class="mdi mdi-menu-right"></i>
          <span class="text-gray-600">{{ product.product_name }}</span>
        </span>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-3">
          <img
            :src="product.product_photo"
            class="w-full h-48 object-cover object-center rounded-md"
            v-if="product.product_photo"
          />
          <q-default-img
            class="w-full h-48 object-contain"
            v-else
          ></q-default-img>
        </div>
        <div class="col-span-8 md:col-span-7 flex flex-col">
          <h1 class="text-xl font-bold">{{ product.product_name }}</h1>
          <p v-html="product.product_description"></p>
          <router-link
            :to="`/${$route.params.lang}/store/${product.company_id}`"
            class="text-gray-400 hover:underline"
            >{{ product.company_name }}</router-link
          >
          <router-link
            :to="
              `/${$route.params.lang}/live?room=${product.product_id}&company_id=${product.company_id}`
            "
            class="bg-red-300 w-32 rounded-md text-white text-center py-1"
            v-if="isDigital && canStream"
          >
            <i class="mdi mdi-access-point"></i>
            Go Live
          </router-link>
          <router-link
            :to="
              `/${$route.params.lang}/live?room=${product.product_id}&company_id=${product.company_id}`
            "
            class="bg-secondary-300 w-32 rounded-md text-white text-center py-1"
            v-if="isDigital && !canStream"
          >
            <i class="mdi mdi-devices"></i>
            Guarda
          </router-link>
        </div>
        <div class="col-span-4 md:col-span-2 flex flex-col">
          <h2 class="font-bold text-xl text-right md:text-left">
            {{ product.single_unit_price | price }}
          </h2>
          <div class="flex-grow">&nbsp;</div>
          <q-button :cta="true" class="" @click="addItem(product)"
            >Acquista <i class="mdi mdi-cart-arrow-down text-xl "></i
          ></q-button>
        </div>
      </div>
    </main>
    <section class="pt-4 mt-4 border-t border-gray-200">
      <h1 class="font-medium py-4 text-2xl md:text-3xl text-gray-600">
        Altri prodotti interessanti per te
      </h1>
      <div class="grid grid-cols-2 md:grid-cols-6 gap-4">
        <a
          :href="prod.product_id"
          v-for="(prod, p) in similarProducts"
          :key="p"
        >
          <img
            :src="prod.product_photo"
            class="w-full h-32 object-cover mb-8"
          />
          <span class="text-gray-600">{{ prod.product_name }}</span>
        </a>
      </div>
    </section>
    <!-- <div class="flex">Maggiori dettagli sul prodotto</div>
    <div
      class="border border-gray-300 bg-gray-200 rounded-md p-4"
      v-html="product.product_description"
    >
      Info
    </div> -->
  </div>
</template>

<script>
import { API_URL } from "@/app-axios";
import { mapActions } from "vuex";
export default {
  name: "SingleProduct",
  data() {
    return {
      product: null,
      products: [],
      similarProducts: [],
      showDetail: false
    };
  },
  async mounted() {
    try {
      API_URL({
        url: `/products/${this.$route.params.id}`
      }).then(prod => {
        this.product = prod.data;
        API_URL({
          url: "/products",
          method: "get",
          params: {
            published: true,
            company: prod.company_id
          },
          headers: {
            "aws-access-token": this.$store.getters["auth/token"]
          }
        }).then(sp => {
          console.log("Similar ", sp);
          this.similarProducts = sp.data.content.slice(0, 6);
        });
      });
      if (this.$store.getters["auth/isMerchant"]) {
        let merchantProd = await API_URL({
          url: "/products",
          method: "get",
          params: {
            company: this.$store.getters["auth/companyId"]
          },
          headers: {
            "aws-access-token": this.$store.getters["auth/token"]
          }
        });
        console.log("Merchant Prod ", merchantProd);
        this.products = merchantProd.data.content;
      }
      console.log("ID ", this.product);
    } catch (error) {
      console.log("Errore di caricamento del prodotto ", error);
    }
  },
  computed: {
    isDigital() {
      return this.product.product_type == "digital";
    },
    canStream() {
      return this.products.some(p => p.product_id == this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("cart", ["addItem"]),
    isProductStreammable() {
      if (this.products.some(p => p.product_id === this.$route.params.id)) {
        console.log("Il prodotto è stream");
      } else {
        console.log("prodotto NON stream");
      }
      return (
        this.products.some(p => p.product_id === this.$route.params.id) &&
        this.product.product_type == "digital"
      );
    }
  }
};
</script>

<style></style>
